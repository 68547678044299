import React from "react";
import { Link } from "gatsby";

import SEO from "../blocks/layout/seo";
import HomeLayout from "../blocks/layout/home";

import "../styles/privacy-policy.scss";

const PrivacyPolicy = () => (
  <HomeLayout>
    <SEO title="Privacy & Policy" keywords={[]} />
    <div>
      <div className="privacy-policy">
        <div className="privacy-content">
          <div className="tag">LEGAL</div>
          <h2>
            <strong>Terms of Service</strong>
          </h2>

          <h2>
            <strong>Welcome to Output!</strong>
            </h2>
            <p>
            <strong>Introduction</strong> 
            </p>
            <p>
            Thank you for using the Output
            platform and the products, services and features we make available
            to you as part of the platform (collectively, the “Service”). 
            </p>
            <p>
            <strong>Our Service</strong> 
            </p>
            <p>
            The Service allows you to discover and share videos and
            other content, provides a marketplace for content creators and
            businesses to connect, and acts as a distribution and syndication
            platform, and technology provider for original content creators and
            distribution partners large and small. We provide information about
            our products and how to use them in our FAQs.
            </p>
            <p> 
                  <strong>Your Service Provider</strong> 
            </p>
            <p>
            The entity providing the Service is SD Technologies, Inc., a company
            operating under the laws of Delaware, located at 2055 Limestone
            Road, Wilmington Delaware 19808 (referred to as “Output”, “we”,
            “us”, or “our”). References to Output’s “Affiliates” in these terms
            means the other companies within the SD Technologies, Inc. affiliate
            group of business relationships (now or in the future). 
            </p>
            <p>
            <strong>Applicable Terms</strong>
            </p> 
            <p>
            Your use of the Service is subject to these terms (this
            "Agreement"). Any other links or references provided in these terms
            are for informational use only and are not part of the Agreement.
            Please read this Agreement carefully and make sure you understand
            it. If you do not understand the Agreement, or do not accept any
            part of it, then you may not use the Service.
            </p>
            <h2> <strong>Who may use the
            Service?</strong> 
            </h2>
            <p>
              <strong>Age Requirements</strong> 
              </p>
              <p>You must be at least 13 years old to use
            the Service; however, children of all ages may use the Service if
            enabled by a parent or legal guardian.
            </p>
            <p>
             <strong>Permission by Parent or
            Guardian </strong>
            </p>
            <p>If you are under 18, you represent that you have your
            parent or guardian’s permission to use the Service. Please have them
            read this Agreement with you. </p>
            
            <p>If you are a parent or legal guardian
            of a user under the age of 18, by allowing your child to use the
            Service, you are subject to the terms of this Agreement and
            responsible for your child’s activity on the Service.</p>
            
             <p>
              <strong>Businesses</strong>
              </p>
            <p>
            If you are using the Service on behalf of a company or organization,
            you represent that you have authority to act on behalf of that
            entity, and that such entity accepts this Agreement. 
            </p>
            <h2><strong>Your Use of the
            Service</strong></h2>
            <p>
              <strong>Content on the Service</strong>
              </p>
              <p>
               The content on the Service includes
            videos, audio (for example music and other sounds), graphics,
            photos, text (such as comments and scripts), branding (including
            trade names, trademarks, service marks, or logos), interactive
            features, software, metrics, and other materials whether provided by
            you, Output or a third-party (collectively, "Content”).
            </p>
            <p>
             Content is
            the responsibility of the person or entity that provides it to the
            Service. Output is under no obligation to host or serve Content. If
            you see any Content you believe does not comply with this Agreement,
            you can report it to us. </p>
            
            <p><strong>Output Accounts </strong>
            </p>
            <p>
              With an Output account, you
            may be able to subscribe and acquire videos, channels, create your
            own channel to distribute, and more.</p>
            <p> Importing or creating an Output
            channel will give you access to additional features and functions,
            such as uploading videos or creating feeds (where available). </p>
            <p><strong>Your
            Information</strong></p> 
            <p>Our Privacy Policy explains how we treat your personal
            data and protect your privacy when you use the Service.</p>
            
            <p> <strong>Permissions
            and Restrictions</strong> 
            </p>
            <p>
            You may access and use the Service as made
            available to you, as long as you comply with this Agreement and
            applicable law. </p>
            
            <p>The following restrictions apply to your use of the
            Service. You are not allowed to: 1. access, reproduce, download,
            distribute, transmit, broadcast, display, sell, license, alter,
            modify or otherwise use any part of the Service or any Content
            except: (a) as expressly authorized by the Service; or (b) with
            prior written permission from Output and, if applicable, the
            respective rights holders; 2. circumvent, disable, fraudulently engage
            with, or otherwise interfere with any part of the Service (or
            attempt to do any of these things), including security-related
            features or features that (a) prevent or restrict the copying or
            other use of Content or (b) limit the use of the Service or Content;
            3. access the Service using any automated means (such as robots,
            botnets or scrapers) except (a) in the case of public search
            engines, in accordance with Output’s robots.txt file; or (b) with
            Output’s prior written permission; 4. collect or harvest any
            information that might identify a person (for example, usernames or
            faces), unless permitted by that person or allowed under section (3)
            above; 5. use the Service to distribute unsolicited promotional or
            commercial content or other unwanted or mass solicitations; 6. cause or
            encourage any inaccurate measurements of genuine user engagement
            with the Service, including by paying people or providing them with
            incentives to increase a video’s views, likes, or dislikes, or to
            increase a channel’s subscribers, or otherwise manipulate metrics in
            any manner; 7. misuse any reporting, flagging, complaint, dispute, or
            appeals process, including by making groundless, vexatious, or
            frivolous submissions; </p>
            <p>
              <strong>Reservation</strong> </p>
              <p>Using the Service does not give
            you ownership of or rights to any aspect of the Service, including
            user names or any other Content posted by others or Output.
            </p> 
            <p>
            <strong>Develop,
            Improve and Update the Service</strong>
            </p>
            <p>
             Output is constantly changing and
            improving the Service. As part of this continual evolution, we may
            make modifications or changes (to all or part of the Service) such
            as adding or removing features and functionalities, offering new
            digital content or services or discontinuing old ones. We may also
            need to alter or discontinue the Service, or any part of it, in
            order to make performance or security improvements, make changes to
            comply with law, or prevent illegal activities on or abuse of our
            systems. These changes may affect all users, some users or even an
            individual user. When the Service requires or includes downloadable
            software, that software may update automatically on your device once
            a new version or feature is available, subject to your device
            settings. If we make material changes that negatively impact your
            use of the Service, we’ll provide you with reasonable advance
            notice, except in urgent situations such as preventing abuse,
            responding to legal requirements, or addressing security and
            operability issues. We’ll also provide you with an opportunity to
            export your content from your Output Account, subject to applicable
            law and policies. </p>
            <h2><strong>Your Content and Conduct</strong></h2> 
            <p><strong>Uploading Content</strong></p>
            <p> If you
            have an Output account, you may be able to upload Content to the
            Service. You may use your Content to promote your business or
            artistic enterprise. If you choose to upload Content, you must not
            submit to the Service any Content that does not comply with this
            Agreement or the law. For example, the Content you submit must not
            include third-party intellectual property (such as copyrighted
            material) unless you have permission from that party or are
            otherwise legally entitled to do so. You are legally responsible for
            the Content you submit to the Service. We may use automated systems
            that analyze your Content to help detect infringement and abuse,
            such as spam, malware, and illegal content. <p/>
            <p>
              <strong>Rights you Grant </strong></p>
              You retain ownership rights in your Content. However, we do require you
            to grant certain rights to Output and other users of the Service, as
            described below. License to Output By providing Content to the
            Service, you grant to Output a worldwide, non-exclusive,
            royalty-free, sublicensable and transferable license to use that
            Content (including to reproduce, distribute, prepare derivative
            works, display and perform it) in connection with the Service and
            Output’s (and its successors' and Affiliates') business, including
            for the purpose of promoting and redistributing part or all of the
            Service. License to Other Users You also grant each other user of
            the Service a worldwide, non-exclusive, royalty-free license to
            access your Content through the Service, and to use that Content,
            including to reproduce, distribute, prepare derivative works,
            display, and perform it, only as enabled by a feature of the Service
            (such as video playback or embeds). For clarity, this license does
            not grant any rights or permissions for a user to make use of your
            Content independent of the Service. Duration of License The licenses
            granted by you continue for a commercially reasonable period of time
            after you remove or delete your Content from the Service. You
            understand and agree, however, that Output may retain, but not
            display, distribute, or perform, server copies of your videos that
            have been removed or deleted. Right to Monetize You grant to Output
            the right to monetize your Content on the Service (and such
            monetization may include displaying ads on or within Content, or
            charging users a fee for access, or syndication of the Content to
            affiliate partners including but not limited to online video
            players, partner websites, over the top (OTT), connected devices,
            free ad supported tv (FAST), online streaming, web syndication,
            portals, broadcast, cable and any other device, hardware or internet
            based application that can display the Content). This Agreement does
            not entitle you to any payments. Starting June 1, 2021, any payments
            you may be entitled to receive from Output under any other agreement
            between you and Output including for example payments under the
            Output Partner Program will be treated as royalties. If required by
            law, Output will withhold taxes from such payments. Removing Your
            Content You may remove your Content from the Service at any time.
            You also have the option to make a copy of your Content before
            removing it. You must remove your Content if you no longer have the
            rights required by these terms. Removal of Content By Output If we
            reasonably believe that any of your Content (1) is in breach of this
            Agreement or (2) may cause harm to Output, our users, or third
            parties, we reserve the right to remove or take down that Content in
            accordance with applicable law. We will notify you with the reason
            for our action unless we reasonably believe that to do so: (a) would
            breach the law or the direction of a legal enforcement authority or
            would otherwise risk legal liability for Output or our Affiliates;
            (b) would compromise an investigation or the integrity or operation
            of the Service; or (c) would cause harm to any user, other third
            party, Output or our Affiliates. Copyright Protection If you believe
            your copyright has been infringed on the Service, please contact us
            via hi@output.ws. Account Suspension & Termination Terminations by
            You You may stop using the Service at any time. Terminations and
            Suspensions by Output Output reserves the right to suspend or
            terminate your Output account or your access to all or part of the
            Service if (a) you materially or repeatedly breach this Agreement;
            (b) we are required to do so to comply with a legal requirement or a
            court order; or (c) we believe that there has been conduct that
            creates (or could create) liability or harm to any user, other third
            party, Output or our Affiliates. Notice for Termination or
            Suspension We will notify you with the reason for termination or
            suspension by Output unless we reasonably believe that to do so: (a)
            would violate the law or the direction of a legal enforcement
            authority; (b) would compromise an investigation; (c) would
            compromise the integrity, operation or security of the Service; or
            (d) would cause harm to any user, other third party, Output or our
            Affiliates. Other Legal Terms Warranty Disclaimer OTHER THAN AS
            EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE
            SERVICE IS PROVIDED “AS IS” AND Output DOES NOT MAKE ANY SPECIFIC
            COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T
            MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE
            SERVICE; (B) THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY,
            RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C)
            THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.
            Limitation of Liability EXCEPT AS REQUIRED BY APPLICABLE LAW,
            OUTPUT, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS
            WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS
            OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION
            OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:
            ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE; PERSONAL INJURY OR
            PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE; ANY
            UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE; ANY INTERRUPTION OR
            CESSATION OF THE SERVICE; ANY VIRUSES OR MALICIOUS CODE TRANSMITTED
            TO OR THROUGH THE SERVICE BY ANY THIRD PARTY; ANY CONTENT WHETHER
            SUBMITTED BY A USER OR Output, INCLUDING YOUR USE OF CONTENT; AND/OR
            THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT. THIS PROVISION APPLIES
            TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON
            WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY. OUTPUT AND ITS
            AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING
            TO THE SERVICE IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF
            REVENUE THAT OUTPUT HAS PAID TO YOU FROM YOUR USE OF THE SERVICE IN
            THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO OUTPUT,
            OF THE CLAIM AND (B) USD $500. Indemnity To the extent permitted by
            applicable law, you agree to defend, indemnify and hold harmless
            Output, its Affiliates, officers, directors, employees and agents,
            from and against any and all claims, damages, obligations, losses,
            liabilities, costs or debt, and expenses (including but not limited
            to attorney's fees) arising from: (i) your use of and access to the
            Service; (ii) your violation of any term of this Agreement; (iii)
            your violation of any third party right, including without
            limitation any copyright, property, or privacy right; or (iv) any
            claim that your Content caused damage to a third party. This defense
            and indemnification obligation will survive this Agreement and your
            use of the Service. Third-Party Links The Service may contain links
            to third-party websites and online services that are not owned or
            controlled by Output. Output has no control over, and assumes no
            responsibility for, such websites and online services. Be aware when
            you leave the Service; we suggest you read the terms and privacy
            policy of each third-party website and online service that you
            visit. About this Agreement Changing this Agreement We may change
            this Agreement (1)to reflect changes to our Service or how we do
            business - for example, when we add new products or features or
            remove old ones, (2) for legal, regulatory, or security reasons, or
            (3) to prevent abuse or harm. If we materially change this
            Agreement, we’ll provide you with reasonable advance notice and the
            opportunity to review the changes, except (1) when we launch a new
            product or feature, or (2) in urgent situations, such as preventing
            ongoing abuse or responding to legal requirements. If you don’t
            agree to the new terms, you should remove any Content you uploaded
            and stop using the Service. Continuation of this Agreement If your
            use of the Service ends, the following terms of this Agreement will
            continue to apply to you: “Other Legal Terms”, “About This
            Agreement”, and the licenses granted by you will continue as
            described under “Duration of License”. Severance If it turns out
            that a particular term of this Agreement is not enforceable for any
            reason, this will not affect any other terms. No Waiver If you fail
            to comply with this Agreement and we do not take immediate action,
            this does not mean that we are giving up any rights that we may have
            (such as the right to take action in the future). Interpretation In
            these terms, “include” or “including” means “including but not
            limited to,” and any examples we give are for illustrative purposes.
            Governing Law All claims arising out of or relating to these terms
            or the Service will be governed by the state of Quebec, Canada, 
            and will be litigated exclusively in the federal or state courts of Quebec,
            Canada. You and Output consent to personal jurisdiction in those
            courts. Effective as of Feb 7, 2023
          </p>
        </div>
      </div>
    </div>
  </HomeLayout>
);

export default PrivacyPolicy;
